import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { theme } from "../theme"
import Layout from "../components/layout"
import { createGlobalStyle } from "styled-components"
import HomeBg from "../../content/assets/img/home-bg.jpg"

const GlobalStyles = createGlobalStyle`
@font-face {
  font-family: MontserratRegular;
  src: url(${require("../../content/assets/fonts/Montserrat-Regular.ttf")});
}
@font-face {
  font-family: MontserratBold;
  src: url(${require("../../content/assets/fonts/Montserrat-SemiBold.ttf")});
}
@font-face {
  font-family: LeagueSpartanBold;
  src: url(${require("../../content/assets/fonts/LeagueSpartan-Bold.otf")});
}
  a{
    text-decoration: none!important;
  }
`

class Directivos extends React.Component {
  render() {
    const { data } = this.props

    const siteTitle = data.site.siteMetadata.title

    const renderCarouselContent = () => {
      return (
        <>
          <SearchContainer></SearchContainer>
        </>
      )
    }
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <GlobalStyles />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "inital",
            paddingRight: 40,
            paddingLeft: 40,
            marginBottom: 40,
          }}
        >
          <Title>Directivos</Title>
          <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'space-evenly', flexWrap: 'wrap'}}>
          <div >
            <ul>
              <li>Presidente: Crio. (R) Dr. Carlos Bustamante.</li>
              <li>Vicepresidente: Crio. Gral. (R) Zosimo Nogueira.</li>
              <li>Secretario General:  Sub. Crio. Dr Julio López.</li>
              <li>Tesorero: Crio. May. (R) Ricardo Laguna.</li>
              <li>
                Pro Secreatrio: Crio. May. (R) Ricardo de León.
              </li>
              <li>Secretaria de Actas: Sub. Crio. (R) Ma. Julia Cossio.</li>
              <li>Pro Tesorero: Crio. May. (R) Rodolfo Dominguez.</li>
<li>Crio. Gral. (R) Daniel Etchverry.</li>
              <li>Crio. May. (R) Ernesto Carreras.</li>
              <li>
                Crio. (R) Abel Idiarte.
              </li>
              <li>Crio. May. (R) Tolentino De Los Santos.</li>
                 
            </ul>
          </div>
          <div >
              <SubTitle>
                Comisión Fiscal
              </SubTitle>
              <ul>
              <li>Presidente: Crio. Gral. (R) Enrique Navas.</li>
              <li>Crio. May (R) Pedro Montero.</li>
              <li>
              Crio. May (R) Jorge Pujol
              </li>
            </ul>
          </div>
          </div>
        </div>
      </Layout>
    )
  }
}

// :

const Title = styled.h1`
  font-family: LeagueSpartanBold;
  color: ${theme.themeColor};
  margin-top: 40px;
  margin-bottom: 20px;

  text-align: center;
`
const SubTitle = styled.h3`
  font-family: LeagueSpartanBold;
  color: ${theme.themeColor};
  margin-top: 40px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 22px;
    text-align: center;
  }
`
const SearchContainer = styled.div`
  height: 88vh;
  margin: 0;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;

  background-image: url(${HomeBg});
  background-size: cover;
  background-position: center center;
  img {
    padding-top: 5rem;
  }
  h3 {
    color: #fff;
    font-family: LeagueSpartanBold;
    font-size: 25px;
  }
  @media (max-width: 768px) {
    .row-mobile {
      flex-wrap: wrap;
    }
    background-image: url(${HomeBg});
    h3 {
      font-size: 15px;
      text-align: center;
      padding: 3rem;
    }
    img {
      width: 15rem;
    }
  }
`

export default Directivos

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx {
      nodes {
        frontmatter {
          date
          description
          path
          title
        }
      }
    }
  }
`
